import axios from "axios";
import { headerKey, headerKey_ } from "./api-header";
import { config } from "../config/config.js";
import logger from "../utils/logger";
import { checkNetworkError } from "../utils/util-functions.js";

const API_BASE_URL = config.REACT_APP_BASE_URL;

const signup = async (firstName, lastName, dob, email, password,subscribeEmail) => {
  const response = await axios.post(API_BASE_URL + "/api/v1/users", {
    username: email,
    first_name: firstName,
    last_name: lastName,
    // age: getAge(dob),
    email: email,
    password: password,
    role: "Admin",
    subscription_status:subscribeEmail ? 1 : 0 ,
  });
  return response.data;
};

const login = async (email, password) => {
  const response = await axios.post(API_BASE_URL + "/api/v1/users/login", {
    email,
    password,
    role: "Admin",
  });
  return response.data;
};

const verifyUser = async () => {
  const response = await axios.post(
    API_BASE_URL + "/api/v1/users/verify",
    {},
    {
      headers: { ...headerKey },
    }
  );
  return response.data;
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("username");
  window.location.reload();
};

async function verifyEmail(token, email, state, setState, toGo) {
  try {
    const resRequest = await axios.put(
      API_BASE_URL + `/api/v1/users/email/${email}/verify-email`,
      { verifyToken: token }
    );
    const { message, data } = resRequest?.data;

    setState({
      msg: message,
      success: true,
      token: resRequest?.data?.token,
      role: "Admin",
      username: data.username,
    });
  } catch (err) {
    setState({ ...state, msg: err.response.data.message || err.message });
    setTimeout(() => {
      toGo("/admin/register");
    }, 5000);
  }
}

async function forgotPwdApi(step, reqBody, setStep, setAlert) {
  try {
    const reqParams = {
      0: "/email/request-pwd-reset",
      1: "/password/reset-pwd",
    };
    if (step === 0 && !reqBody.email)
      return setAlert({
        open: true,
        msg: `Can't leave it blank 😕.`,
        type: "warning",
        loading: false,
      });
    if (step === 1 && !reqBody.newPassword)
      return setAlert({
        open: true,
        msg: `Can't leave it blank 😕.`,
        type: "warning",
        loading: false,
      });
    if (step === 1 && reqBody.newPassword !== reqBody.confirmPassword)
      return setAlert({
        open: true,
        msg: `Passwords doesn't match.`,
        type: "warning",
        loading: false,
      });
    if (step === 0) {
      reqBody["role"] = "admin";
    }
    const resRequest = await axios.put(
      API_BASE_URL + `/api/v1/users${reqParams[step]}`,
      { ...reqBody }
    );
    const { success, message } = resRequest.data;
    if (!success)
      return setAlert({
        open: true,
        msg: message,
        type: "error",
        loading: false,
      });
    // From here means -> Success
    if (step === 0) {
      setAlert({ open: true, msg: message, type: "success", loading: false });
      return "";
    } else {
      setStep(2);
      setAlert({ ...reqBody, loading: false });
      return "";
    }
  } catch (err) {
    logger("./service/auth-services.jsx", "forgotPwdApi(): catch", err);
    if (err.response.data) {
      setAlert({
        open: true,
        msg: err.response.data.message || err.message,
        type: "error",
        loading: false,
      });
    } else {
      setAlert({ open: true, msg: err.message, type: "error", loading: false });
    }
    return "";
  }
}

const checkPermissions = async (permission_id, event_id) => {
  try {
      const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/id/${event_id}/check-event-permission/${permission_id}`, {
        headers: {...headerKey_}
      })
      return data
  } catch (error) {
    // throw error
    if(!!checkNetworkError(error)) {
      return {
        access: false,
      };
    }
  }
};

const checkLimitedPermissions = async (permission_id) => {
  try {
      const {data} = await axios.get(`${API_BASE_URL}/api/v1/auction/check-limited-permission/${permission_id}`, {
      headers: {...headerKey_}
      })
      return data
  } catch (error) {
    // throw error
    if(!checkNetworkError(error)) {
      return {
        access: false,
      };
    }
  }
};

const checkCausePermissions = async (permission_id, cause_id) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/api/v1/cause/id/${cause_id}/check-cause-permission/${permission_id}`,
      {
        headers: { ...headerKey_ },
      }
    );
    return data;
  } catch (error) {
    console.log(error);
    return {
      access: false,
    };
  }
};

async function changeEmail(body) {
  const data = await axios.post(
    API_BASE_URL + "/api/v1/users/change-email",
    body
  );
  return data;
}

async function verifyChangeEmail(token, email, state, setState, toGo) {
  try {
    const resRequest = await axios.put(
      API_BASE_URL + `/api/v1/users/email/${email}/verify-change-email`,
      { verifyToken: token }
    );
    const { message } = resRequest;
    setState({ msg: message, success: true });
  } catch (err) {
    setState({ ...state, msg: err.response.data.message || err.message });
    setTimeout(() => {
      toGo("/admin/register");
    }, 5000);
  }
}

async function fetchAllUsers(limit, page) {
  try {
    const data = await axios.get(
      `${API_BASE_URL}/api/v1/users/getAllUsers/${limit}/${page}`,
      {
        headers: { ...headerKey_ },
      }
    );
    return data;
  } catch (error) {
  }
}

async function fetchPermissions() {
  try {
    const data = await axios.get(
      `${API_BASE_URL}/api/v1/users/permissions/getAllPermissions`,
      {
        headers: { ...headerKey_ },
      }
    );
    return data;
  } catch (error) {
  }
}

export const assignPermissions = async (id, permissions, user_id) => {
  try {
    const data = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${id}/assign-permissions`,
      { permissions, user_id },
      {
        headers: { ...headerKey_ },
      }
    );
    return data;
  } catch (error) {
  }
};

export const makePermissionInviteEmail = async (id, permissions, email, setAlertMsg) => {
  try {
    const data = await axios.post(`${API_BASE_URL}/api/v1/auction/id/${id}/make_permission_invite`,
      { permissions, email },
      {
        headers: { ...headerKey_ },
      }
    );
    return data;
  } catch (error) {
    console.log(error.response.data.message)
   setAlertMsg(error.response.data.message)
  }
}


export const checkBlogPermissions = async() => {
    try {
      const data = await axios.get(`${API_BASE_URL}/api/v1/users/blogs/verify-blog-permission`, {
        headers: { ...headerKey_ },
      })
      return data
    } catch (error) {
      console.log(error)
    }
}

export const getUserPermissions = async (aucId) => {
  try {
    const data = await axios.get(`${API_BASE_URL}/api/v1/users/admin/permissions`, {
      params: { aucId },
      headers: { ...headerKey_ },
    })
    return data
  } catch (error) {
    console.log(error)
  }
}


const authService = {
  signup,
  login,
  verifyUser,
  logout,
  verifyEmail,
  forgotPwdApi,
  checkPermissions,
  changeEmail,
  verifyChangeEmail,
  checkLimitedPermissions,
  checkCausePermissions,
  fetchAllUsers,
  fetchPermissions,
  assignPermissions,
  makePermissionInviteEmail,
  checkBlogPermissions,
  getUserPermissions
};

export default authService;
