import React, { useEffect, useState } from 'react'
import Input from "../../../components/Input/Input"
import FormTextarea from "../../../components/FormTextarea/FormTextarea"
import DropDown from '../../../components/DropDown/DropDown'
import ImageInput from '../../../components/ImageInput/ImageInput'
import auctionServices from '../../../../services/auction-services'
import checkImageBeforeUpload from "../../../../utils/checkImageBeforeUpload"
import {useDispatch, useSelector} from "react-redux"
import LoadingModal from "../../../components/LoadingModal/LoadingModal"
import "./StoreItem.css"
import Modal from '../../../components/Modal/Modal'
import OptionsModalContent from './OptionsModalContent'
import VaraintsModalContent from './VaraintsModalContent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Checkbox, FormControlLabel, Radio, Tooltip, Typography } from '@mui/material'
import Alert from "../../../components/Alert/Alert"
import { useNavigate, useLocation } from 'react-router-dom'
import usePrompt from '../../../hooks/usePrompt'
import { RadioGroup } from '@mui/material';
import causeServices from '../../../../services/cause-services';
import Multiselect from 'multiselect-react-dropdown';
import constants, { COLORS_OBJECT } from '../../../../constants/en'
import RadioGroupComponent from '../../../components/RadioGroup/RadioGroup'
import { clearAttribute, setStoreItemImages, setStoreItemVirtualAssets, toggleBooleanState } from '../../../../redux/persistReducer'
import usePopState from '../../../hooks/usePopState'
import ClearButton from '../../../components/ClearButtonContainer/ClearButtonContainer'
import { setItemToLocalStorage } from '../../../../utils/autoSave'
import AIGeneratedData from '../../../components/AIGeneratedData/AIGeneratedData'
import { checkVendorSkuOfVariants } from '../../../../utils/util-functions'

const redColor = COLORS_OBJECT.color[0]

const variantCombination = ([head, ...[tailHead, ...tailTail]]) => {
  if (!tailHead) return head;

  const combined = tailHead.reduce((acc, x) => {
    return acc.concat(head.map((h) => `${h}/${x}`));
  }, []);
  return variantCombination([combined, ...tailTail]);
};

const getExtention = (file_name) => file_name ? file_name?.split('.')[file_name.split('.')?.length - 1] : undefined;

const StoreItem = ({categories, reload, setShowPrompt, showPrompt, setCurrentTab, setCurrentComponentToSave, godAccess }) => {
  const location = useLocation()
  let localStoreDataName = 'eventStoreItem';
  let localStoreExtraDataName = 'eventExtraStoreItem'
  let keyName = 'event'
  if(location.pathname === '/admin/market-place/items/add') {
     localStoreDataName = 'marketplaceStoreItem';
     localStoreExtraDataName = 'marketplaceExtraStoreItem'
     keyName = 'marketplace'
  }
  if(location.pathname === '/admin/store/items/add') {
    localStoreDataName = 'storeStoreItem';
    localStoreExtraDataName = 'storeExtraStoreItem'
    keyName = 'store'
 }
  const localStorageData = JSON.parse(localStorage.getItem(localStoreDataName))
  const localStorageExtraStoreItemData = JSON.parse(localStorage.getItem(localStoreExtraDataName)) || {}
  const dispatch = useDispatch()
  const persistedData = useSelector(state => state?.persistedData)
  let initialData = {
    name: "",
    description: "",
    short_description: "",
    auction_id: null,
    // auction_item_category_id: null,
    // custom_category_id: null,
    category_ids: [],
    custom_category_ids: [],
    donor_id: null,
    price: "",
    upc: "",
    list_price: "",
    discounted_price: "",
    quantity_in_stock: 0,
    width: "",
    height: "",
    length: "",
    weight: "",
    is_virtual: 0,
    charge_tax: 0,
    track_inventory: 1,
    sell_when_out_of_stock: 0,
    options: {},
    variants: [],
    auction_item_amount_given_dialog:null,
    show_stock_availability: true,
    vendor_sku_id: ''
  }
  
  const initialStateCopy = {...initialData}
  
  if(localStorageData) {
    initialData = localStorageData
  }

  /// these two are images peristed in redux for store and marketplace
  let persistStoreItemImages = persistedData[`${keyName}StoreItemImages`]
  let persistStoreItemVirtualAssets = persistedData[`${keyName}StoreItemVirtualAsset`]

  let virtualAssetReduxName = `${keyName}StoreItemVirtualAsset`
  let storeItemImagesReduxName = `${keyName}StoreItemImages`

  const {auction_id, auction_status} = useSelector(state => state.dependencies)
  const stateData = useSelector(state => state.dependencies)
  const [storeItem, setStoreItem] = useState(initialData);

  const [itemType, setItemType] = useState(localStorageExtraStoreItemData?.storeItemItemType ? localStorageExtraStoreItemData?.storeItemItemType : "physical")
  const [openShipping, setOpenShipping] = useState(false)

  const [showVariants, setShowVariants] = useState(false)
  const [showOptions, setShowOptions] = useState(false)

  const [localVariants, setLocalVariants] = useState(localStorageData?.variants?.length ? localStorageData?.variants : [])

  // const [imageObjects1, setImageObjects1] = useState("")
  // const [imageObjects2, setImageObjects2] = useState(persistStoreItemImages?.length ? persistStoreItemImages : [])
  const [imageObjects2, setImageObjects2] = useState([])
  // const [portraitImage, setPortraitImage] = useState(null);
  // const [landscapeImages, setLandscapeImages] = useState((persistStoreItemImages?.length && localStorageExtraStoreItemData?.storeItemLandscapeImages) ? localStorageExtraStoreItemData?.storeItemLandscapeImages : []);
  const [landscapeImages, setLandscapeImages] = useState([]);
  // const [portraitImageLoading, setPortraitImageLoading] = useState(false)
  const [landscapeImageLoading, setLandscapeImageLoading] = useState(false)
  const [optionsUpdated, setOptionsUpdated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [virtualForm, setVirtualForm] = useState(null)
  const [isFlatRate, setIsFlatRate] = useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemIsFlatRate') ? localStorageExtraStoreItemData?.storeItemIsFlatRate : false)
  const [isTaxable, setIsTaxable] = useState(Object.keys(localStorageExtraStoreItemData)?.includes("storeItemIsTaxable") ? localStorageExtraStoreItemData?.storeItemIsTaxable : false)
  const [hasUPC, setHasUPC] = useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemHasUPC') ? localStorageExtraStoreItemData?.storeItemHasUPC : false)
  const [formError, setFormError] = useState(null)
  const navigate = useNavigate()
  // const [showPrompt, setShowPrompt] = useState(false)
  const [openDonor, setOpenDonor] = useState(false)
  const [donorsList, setDonorsList] = useState([])
  const [selectedDonor, setSelectedDonor] = useState(localStorageExtraStoreItemData?.storeItemDonor ? localStorageExtraStoreItemData?.storeItemDonor : null)
  const [updateDonors, setUpdateDonors] = useState(true)
  const [localDonor, setLocalDonor] = useState({
    "org_name": "",
    "about": ""
  })
  const [navigateAction, setNavigateAction] = useState('another')
  const [shipmentType,setShipmentType]=useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemShipmentType') ? localStorageExtraStoreItemData?.storeItemShipmentType : false)
  const [ediShipment, setEdiShipment] = useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemEdiShipment') ? localStorageExtraStoreItemData?.storeItemEdiShipment : false);
  const [pickupType,setPickupType]=useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemPickupType') ? localStorageExtraStoreItemData?.storeItemPickupType : false)
  const [allowMultiple,setAllowMultiple]=useState(true)

  usePrompt("Do you want to leave?", showPrompt, localStoreDataName, storeItem)
  usePopState(localStoreDataName, storeItem, showPrompt)

  const [virtualFile,setVirtualFile]=useState(false);
  const [virtualText,setVirtualText]=useState(localStorageExtraStoreItemData?.storeItemVirtualText ? localStorageExtraStoreItemData?.storeItemVirtualText : "");
  // const [virtualFiles,setVirtualFiles]=useState(persistStoreItemVirtualAssets?.length ? persistStoreItemVirtualAssets : [])
  const [virtualFiles,setVirtualFiles]=useState([])
  const [reset, setReset]= useState("")
  const [canChooseCause,setCanChooseCause] = useState(localStorageExtraStoreItemData?.storeItemSelectedCauses ? true : false);
  const [causes,setCauses] = useState([]);
  const [selectedCauses,setSelectedCauses] = useState(localStorageExtraStoreItemData?.storeItemSelectedCauses ? localStorageExtraStoreItemData?.storeItemSelectedCauses : []);
  const [defaultCauses,setDefaultCauses] = useState(null)
  const [selectedDefaultCauseID,setSelectedDefaultCauseId] = useState(localStorageExtraStoreItemData?.storeItemDefaultCauseId ? localStorageExtraStoreItemData?.storeItemDefaultCauseId : null)
  const [causeId,setCauseId] = useState(null)
  const [auctionData, setAuctionData] = useState({})
  const [lowerName, setLowerName] = useState('')
  const [upperName, setUpperName] = useState('')
  const [whiteborderPortraitAlert,setWhiteborderPortraitAlert] = useState(null);
  const [whiteborderLandScapeAlert,setWhiteborderLandScapeAlert] = useState(null);
  const [portraitEvent,setPortraitEvent] = useState(null);
  const [landScapeEvents,setLandScapeEvents] =useState(null) 
  const [categorySelectDisable, setCategorySelectDisable] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(localStorageExtraStoreItemData?.storeItemSelectedCategory ? localStorageExtraStoreItemData?.storeItemSelectedCategory : null)
  const [forCharity, setForCharity] = useState(false)
  const [continueAlert, setContinueAlert] = useState({isOpen:false, message:'', type:''})

  const [aiAnalysis, setAiAnalysis] = useState({
    modalIsOpen:false,
    aiData:null,
    isUsed:false
  })

  useEffect(() => {
    if(!imageObjects2?.length) {
      setAiAnalysis({...aiAnalysis, isUsed:false})
    }
  },[ imageObjects2])

  // let forCharity = false;
  useEffect(() => {
    if(location.pathname === '/admin/items/add'){
      setUpperName('Event')
      setLowerName('event')
    }
    if(location.pathname === '/admin/market-place/items/add'){
      // forCharity = true;
      setForCharity(true)
      setUpperName('Marketplace')
      setLowerName('marketplace')
    }
    if(location.pathname === '/admin/store/items/add'){
      setUpperName('Store')

      setLowerName('store')
    }
  },[])

  const [showAmountDialog, setShowAmountDialog] = useState(Object.keys(localStorageExtraStoreItemData)?.includes('storeItemDialogeChange') ? localStorageExtraStoreItemData?.storeItemDialogeChange : true)
  const [defaultStateChange, setDefaultStateChange] = useState({
    causeIdChange: localStorageExtraStoreItemData?.storeItemDefaultCauseId ? true : false, 
    taxChange: Object.keys(localStorageExtraStoreItemData)?.includes("storeItemIsTaxable") ? true : false, 
    dialogeChange: Object.keys(localStorageExtraStoreItemData)?.includes('storeItemDialogeChange') ? true : false,
    storeItemImageChangeState: localStorageExtraStoreItemData?.storeItemLandscapeImages ? true : false,
    itemTypeChange: localStorageExtraStoreItemData?.storeItemItemType ? true : false,
    pickUpChange: localStorageExtraStoreItemData?.storeItemPickupType ? true : false,
    shipmentChange: localStorageExtraStoreItemData?.storeItemShipmentType ? true : false,
    hasUPCChange: localStorageExtraStoreItemData?.storeItemHasUPC ? true : false,
    isFlatRateChange: localStorageExtraStoreItemData?.storeItemIsFlatRate ? true : false,
    storeItemVirtualFileChange: persistStoreItemVirtualAssets ? true : false,
    ediShipmentChange: localStorageExtraStoreItemData?.storeItemEdiShipment ? true : false,

  })

  useEffect(() => {
    setShowPrompt(false)
    setCurrentComponentToSave('storeItemSave')/// for changing tab after saving the unsaved changes in local storage
  },[])

  const changeShippingType = () => {
    if(godAccess) {
      // if not a god user then we don't allow the user to make the product to advatix.
      setShipmentType(false)
    }
    else {
      setShipmentType(true)
    }
  }

  useEffect(() => {
    changeShippingType()
  },[godAccess])

  useEffect(() => {
    /// for changing tab after saving the unsaved changes in local storage
    if(persistedData?.storeItemSave === true) {
      // localStorage.setItem(localStoreDataName, JSON.stringify(storeItem))
      setItemToLocalStorage(localStoreDataName, storeItem)
      dispatch(toggleBooleanState({key:'storeItemSave', value: false}))
      if(persistedData?.tabValue) {
        setCurrentTab(persistedData?.tabValue)
        dispatch(clearAttribute({key: 'tabValue'}))
      }
    }
  },[persistedData?.storeItemSave])

  useEffect(() => {
    let extraStoreItemState = {}
    /// useEffect for saving all extrastate in localstorage for showing unsaved changes
    if(selectedCategory) {
      extraStoreItemState = {...extraStoreItemState, storeItemSelectedCategory:selectedCategory}
    }
    if(selectedDonor) {
      extraStoreItemState = {...extraStoreItemState, storeItemDonor:selectedDonor}
    }
    if(selectedDefaultCauseID && defaultStateChange.causeIdChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemDefaultCauseId: selectedDefaultCauseID}
    }
    if(selectedCauses?.length) {
      extraStoreItemState = {...extraStoreItemState, storeItemSelectedCauses: selectedCauses}
    }
    if(defaultStateChange.taxChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemIsTaxable: isTaxable}
    }
    if(defaultStateChange?.dialogeChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemDialogeChange: showAmountDialog}
    }
    // if(defaultStateChange.storeItemImageChangeState) {
    //   extraStoreItemState = {...extraStoreItemState, storeItemLandscapeImages: landscapeImages}
    //   dispatch(setStoreItemImages({key:storeItemImagesReduxName, storeItemImages:imageObjects2}))
    // }
    if(defaultStateChange?.itemTypeChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemItemType: itemType}
    }
    if(defaultStateChange.isFlatRateChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemIsFlatRate: isFlatRate}
    }
    if(defaultStateChange.hasUPCChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemHasUPC: hasUPC}
    }
    if(defaultStateChange.shipmentChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemShipmentType: shipmentType}
    }
    if(defaultStateChange.ediShipmentChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemEdiShipment: ediShipment}
    }
    if(defaultStateChange.pickUpChange) {
      extraStoreItemState = {...extraStoreItemState, storeItemPickupType: pickupType}
    }
    if(virtualText !== '') {
      extraStoreItemState = {...extraStoreItemState, storeItemVirtualText: virtualText}
    }
    // if(defaultStateChange.storeItemVirtualFileChange) {
    //   dispatch(setStoreItemVirtualAssets({key:virtualAssetReduxName, storeItemVirtualAsset:virtualFiles}))
    // }
    if(Object.keys(extraStoreItemState)?.length) {
      // localStorage.setItem(localStoreExtraDataName, JSON.stringify(extraStoreItemState))
      setItemToLocalStorage(localStoreExtraDataName, extraStoreItemState)
    }
    /// landscapeImages, imageObjects2,  virtualFiles removed these from dependency because we are not adding 
  },[selectedCategory, selectedDonor, selectedDefaultCauseID, selectedCauses, isTaxable, showAmountDialog, itemType, isFlatRate, hasUPC, shipmentType, pickupType, virtualText, ediShipment])


  const getCauseId = async ()=>{
    let {data} = await auctionServices.getAuctionDetailsById(auction_id)
    setAuctionData(data)
    
    if(Object.keys(localStorageExtraStoreItemData)?.length && localStorageExtraStoreItemData?.storeItemDefaultCauseId
    && (localStorageExtraStoreItemData?.storeItemDefaultCauseId?.length || Object.keys(localStorageExtraStoreItemData?.storeItemDefaultCauseId)?.length)
   ) {
      setSelectedDefaultCauseId(localStorageExtraStoreItemData?.storeItemDefaultCauseId)
    }
    else {
      setSelectedDefaultCauseId([{label:data?.causeDetails?.legal_name,value:data.cause_id}])
    }
    setCauseId(data?.cause_id)
    if(data?.donor_id) setStoreItem({...storeItem, donor_id:data?.donor_id})
  }
  
  useEffect(()=>{
    (async () => {
      const data = await causeServices.getUserCauses()
      let newCauses   
        newCauses = data?.data.map((obj,i)=>{
          return {
            key:obj.cause.legal_name
            ,value:obj.cause_id}
        })
      
      setCauses(newCauses)
      setDefaultCauses(data?.data.map((obj,i)=>{
        return {
          label: obj.cause.legal_name,
          value: obj.cause_id
        }
      }))
    })()
   
  },[])

  useEffect(()=>{
    reset === "" &&  getCauseId()
  },[reset])

  const handleDataChange = (e) => {
    setShowPrompt(true)
    setStoreItem({
      ...storeItem,
      [e.target.name]: e.target.value
    })
  }


//   const handlePortraitImage = async (e,confirm) => {
//     try{
//       if(!e.target.files[0]) return
//     setPortraitImageLoading(true)
//     setShowPrompt(true)
//     const image = e.target.files[0]
//     if(image.size > 5000000) {
//       setShowPrompt(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
//       setPortraitImageLoading(false)
//       return
//     }
//     const imageUrl = URL.createObjectURL(image)
//     const {width, height} = await checkImageBeforeUpload(imageUrl)
//     if(width < 300 || height < 300) {
//       setShowPrompt(false)
//       setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT_SIZE)
//       setPortraitImageLoading(false)
//       return
//     }
//     if(!confirm){
//       if(width/height < 0.98 || width/height > 1){
//         setPortraitEvent(e);
//         setPortraitImageLoading(false)
//         setWhiteborderPortraitAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
//         return
//       }
//     }else{
//       setPortraitEvent(null)
//     }
//     const formData = new FormData()
//     formData.append("image", image)
//     formData.append("width", 300)
//     formData.append("height", 300)
//     const preProcessedImage = await auctionServices.imagePreProcess(formData)
//     setPortraitImage([{name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img'}])
//     const uint8 = new Uint8Array(preProcessedImage.buffer.data)
//     const newBlob = new Blob([uint8], {type: "image/jpeg"})
//     setImageObjects1(newBlob)
//     setPortraitImageLoading(false)
//     setShowPrompt(false)
//     }
//     catch(error){
//     setPortraitImageLoading(false)
//     setShowPrompt(false)
//     }
//   }


  // const handleVirtualAsset = (e) => {
  //   if(virtualItemType === "text"){
  //     setVirtualText(e.target.value);
  //     const vir_upload_form = new FormData();
  //     const ext = "text";
  //     vir_upload_form.append('asset',e?.target?.value);
  //     vir_upload_form.append('type',ext);
  //     setVirtualForm(vir_upload_form,ext);
  //   }
  //   else{
  //     if(e?.target?.files?.length > 1) return alert('Want to upload multiple items? Try Uploading Zip file');
  //     const fln = e?.target?.files[0]?.name;
  //     const ext = getExtention(fln)
  //     if(!ext) return alert('Malfunctioned file, please choose another.');
  //     const vir_upload_form = new FormData();
  //     vir_upload_form.append('asset',e?.target?.files[0]);
  //     vir_upload_form.append('type',ext);
  //     setVirtualForm(vir_upload_form,ext);
  //   }

  // }
  const handlefileChange =(e)=>{
    // setShowPrompt(true)
    if(e.target.files.length > 5){
      alert(constants.ADMIN_COPY_ITEM_VIRTUAL_FILE_MAX)
      return
    }
    else{
      setVirtualFiles((prev)=>{
        return [...prev,...e.target.files]
       })
       setDefaultStateChange({...defaultStateChange, storeItemVirtualFileChange:true})
    }
  }

  const createVirtualAsset = (files,text) =>{
    const form = new FormData();
    let type = [];
    let filename=[];
    form.append("instruction",text);
   files.length > 0 && files.forEach(obj => {
      const fln = obj.name;
      const ext = getExtention(fln)
     form.append("asset", obj)
     type.push(ext);
     filename.push(obj.name)
   });
   form.append("type",JSON.stringify(type))
   form.append("file_name",JSON.stringify(filename))
 return form;
} 

  const handleLandscapeImage = async (e,confirm) => {
   try{
    if(e.target.files.length === 0) return
    // setShowPrompt(true)
    setLandscapeImageLoading(true)
    const filesUrl = []
    const selectedFiles = []
    const targetFilesObj = [...e.target.files]
    const preProcessedFiles = []
    if(targetFilesObj.length > 4 || landscapeImages.length === 4 || (landscapeImages.length + targetFilesObj.length) > 4) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX);
      setLandscapeImageLoading(false)
      setShowPrompt(false)
      return
    }
    if(targetFilesObj.every((currentValue) => currentValue.size <= 5000000)) {
      targetFilesObj.map((file) => {
        return filesUrl.push({name: URL.createObjectURL(file), type: 'img', local: true});
      });

      let invalidImage = false;
      let invalidAspectRatio = false
      for(const url of filesUrl) {
        const {width, height} = await checkImageBeforeUpload(url.name)
        if(width < 600 || height < 600) {
          invalidImage = true
        }
        if(width/height < 0.98 || width/height > 1){
          invalidAspectRatio = true
        }
      }
      if(invalidImage) {
        setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE_MAX_SIZE)
        setLandscapeImageLoading(false)
        setShowPrompt(false)
        return
      }
      if(!confirm){
        if(invalidAspectRatio){
          setLandScapeEvents(e);
          setLandscapeImageLoading(false);
          setWhiteborderLandScapeAlert(constants.ADMIN_COPY_WHITE_BORDER_ALERT_MSG.replace('()','1:1'));
          return
        }
      }else{
        setLandScapeEvents(null);
      }

      for(const image of targetFilesObj) {
        const formData = new FormData()
        formData.append("image", image)
        formData.append("width", 1024)
        formData.append("height", 1024)
        const preProcessedImage = await auctionServices.imagePreProcess(formData)
        selectedFiles.push({name: `data:image/jpeg;charset=utf-8;base64,${preProcessedImage.base64}`, type: 'img', local: true});
        const uint8 = new Uint8Array(preProcessedImage.buffer.data)
        const newBlob = new Blob([uint8], {type: "image/jpeg"})
        preProcessedFiles.push(newBlob)
      }

      setLandscapeImages([...landscapeImages, ...selectedFiles])
      setImageObjects2([...imageObjects2, ...preProcessedFiles])
      setDefaultStateChange({...defaultStateChange, storeItemImageChangeState: true})
      setLandscapeImageLoading(false)
      // setShowPrompt(false)
    } else {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_FILE_SIZE);
      setLandscapeImageLoading(false)
      // setShowPrompt(false)
      return
    }
   }
   catch(error){
      setLandscapeImageLoading(false)
   }
  }

  async function addDonors() {
    try {
      const data = await auctionServices.getdonorsListbyAuctionId(auction_id)
      setDonorsList(data.donors.map((i) => {
        if(i.email) {
          return {
            label: `${i.contact_firstname} ${i.contact_lastname} - ${i.org_name}`,
            value: i.id,
            sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
          }
        }
        return {
          label: i.org_name, value: i.id, sps_edi_trading_partner_id: i?.sps_edi_trading_partner_id
        }
      }));
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    addDonors()
  }, [updateDonors])


  const addOption = (name, values) => {
    setShowPrompt(true)
    if(name === ''|| values?.length === 0) return 
    setStoreItem({
      ...storeItem,
      options: {
        ...storeItem.options,
        [name]: values,
      },
    });
  };

  const deleteOption = (name) => {
    setShowPrompt(true)
    const newOptions = storeItem.options;
    delete newOptions[name];
    setStoreItem({
      ...storeItem,
      options: { ...newOptions },
    });
    onSubmitOptions(true)/// the true is passing because, for checking if the function is calling after deleting variants.
  };

  const updateVariants = (newVariants) => {
    setStoreItem({
      ...storeItem,
      variants: newVariants,
    });
  };

  const onSubmitOptions = (whileDeleting=false) => {
    if(!optionsUpdated && !whileDeleting) {
      setShowOptions(false)
      return
    }
    const combination = variantCombination(Object.values(storeItem.options));
    const obj = [];
    const keys = Object.keys(storeItem.options);
    combination?.forEach((c) => {
      const values = c.split("/");
      let res = {};
      keys.forEach((element, index) => {
        res[element] = values[index];
      });
      res["quantity_in_stock"] = "";
      res["price"] = "";
      res["list_price"] = "";
      res["discounted_price"] = "";
      res["vendor_sku_id"] = "";
      res["key"] = c;
      obj.push(res);
    });
    setLocalVariants(obj)
    updateVariants(obj);
    setOptionsUpdated(false)
    if(whileDeleting) return
    setShowOptions(false)
  }

  const onSubmitVariants = () => {
    updateVariants(localVariants)
    setShowVariants(false)
  }

  const handleCloseVariants = () => {
    setShowVariants(false)
    setLocalVariants(storeItem.variants)
  }

  const prepareSubmitData = (item, afterConfirm=false) => {
    let data = JSON.parse(JSON.stringify(item))
    data.name=data.name?.replace(/\s+/g, " ").trim();
    data.price = +data.price;
    data.list_price = +data.list_price;
    data.auction_id = +auction_id;
    data.list_price = +data.list_price;
    data.discounted_price = +data.discounted_price;
    data.show_stock_availability = item?.show_stock_availability ? 1 : 0
    // data.quantity_in_stock = +data.quantity_in_stock;
    if(itemType === 'physical' && (!shipmentType && !ediShipment)) {//so the item is advatix (not edi and not selfship)
      if(godAccess) {
        data.quantity_in_stock = +data.quantity_in_stock;
      }
      else {
        data.quantity_in_stock = 0
      }
    }
    else {
      data.quantity_in_stock = +data.quantity_in_stock;
    }
    data.auction_item_amount_given_dialog = showAmountDialog ? 1 : 0
    if(itemType === 'physical') {
      data.is_flat_rate_shipping = isFlatRate ? 1 : 0
      data.charge_tax = isTaxable ? 1 : 0
      data.fulfilment_name=shipmentType?'self': ediShipment ? 'edi' : 'advatix';
      data.pickup_type=pickupType?'pickup':'ship';
      data.allow_multiple=allowMultiple?1:0;
    } else {
      data.is_flat_rate_shipping = 0
      data.fulfilment_name='virtual';
      data.pickup_type='virtual';
      data.allow_multiple=1;
    }
    data.is_virtual = itemType !== 'physical' ? 1 : 0;
    data.height = 0;
    data.weight = itemType === 'physical' ? +data.weight : 0;
    data.length = 0;
    data.width  = 0;
    data.variants = data.variants.map((variant) => {
      if (variant.price === "") {
        variant.price = data.price;
      }
      if (variant.list_price === "") {
        variant.list_price = data.list_price;
      }
      if (variant.discounted_price === "") {
        variant.discounted_price = data.discounted_price;
      }
      if(itemType === 'physical' && (!shipmentType && !ediShipment)) {//so the item is advatix (not edi and not selfship)
        if(!godAccess) {
          variant.quantity_in_stock = 0;
        }
      }
      return variant;
    });
    if(data.upc.length < 2) {
      delete data.upc
    }
    if(shipmentType) {
      data.variants = data.variants.filter(
        (variant) => variant.quantity_in_stock !== ""
        );
    }
    data.causes = JSON.stringify(selectedCauses)
    data.can_choose_cause = (canChooseCause) ? 1 : 0
    data.cause_id = causeId
    return data
  } 
  
  const prepareAssetData = (item_id) => {
    const form = new FormData()
    let type = ["img"]
    // imageObjects1 && form.append('portrait', imageObjects1, "portrait.png")
    imageObjects2.forEach(obj => {
      form.append('landscape',obj, "landscape.png")
      type.push('img')
    })
    form.append("type", JSON.stringify(type))
    form.append("auction_item_id", item_id)
    return form;
  }

  function handleQtyCheck(variants,qty) {
    //returns true === 'error' or false 'safe'
    if(Object.keys(storeItem?.options).length === 0) return false
    let v_total = 0;
    if(!variants.length) return true;
    variants.forEach(v=>{
      v_total = v_total + Number(v.quantity_in_stock);
    });
    if(qty !== v_total) return true;
    return false;
  }

  const checkValidity = () => {
    // if(!imageObjects1) {
    //   setFormError(constants.ADMIN_COPY_ITEM_IMG_POTRAIT)
    //   return true
    // }
    if(ediShipment) {
      if(storeItem?.variants?.length) {
        // check vendor_sku_id of all variants.
        const {valid, message} = checkVendorSkuOfVariants(storeItem.variants)
        if(!valid) {
          setFormError(message)
           return true
        }
      }
      else if(!storeItem?.vendor_sku_id || storeItem?.vendor_sku_id?.trim() === '') {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_ALERT)
        return true
      }
    }
    if(storeItem?.variants?.length) {
      const {valid, message} = checkVendorSkuOfVariants(storeItem.variants, false)// false: if not edishipment then we don't need to check if the vendor sku is there. Just check if it is valid or not.
        if(!valid) {
          setFormError(message)
           return true
        }
    }else if(storeItem?.vendor_sku_id?.length && storeItem?.vendor_sku_id?.includes(" ")) {
        setFormError(constants.ADMIN_COPY_ITEM_VENDOR_SKU_ID_EMPTY_ALERT)
        return true
    }
    if(imageObjects2.length === 0) {
      setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
      return true
    }
    if(!storeItem?.category_ids?.length && !storeItem.custom_category_ids?.length) {
      setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
      return true
    }
    if(itemType === 'physical') {
      if(!storeItem.weight && !pickupType) {
        setFormError(constants.ADMIN_COPY_ITEM_PHYSICAL_PROPERTY)
        return true
      }
      return false
    }
    return false
  }

  const handleSubmitError = (error) => {
    if(error.response && error.response.status === 400 && error.response.data) {
      const data = error.response.data;
      if(data.data?.length > 0) {
        setFormError(`${data.data[0].param} - ${data.data[0].msg}`)
      } else {
        setFormError(data.message)
      }
      return;
    }
  }

  const handleSubmit = async (e, afterConfirm=false) => {///afterconfirm is for checking if the function is called after an alert
    if(e) {
      e.preventDefault()
    }
    setShowPrompt(false)
    const qty_confict = handleQtyCheck(storeItem.variants,Number(storeItem.quantity_in_stock));
      if(Object.keys(storeItem.options).length && qty_confict) {
      setFormError(constants.ADMIN_COPY_STORE_ITEM_QUANTITY_ERROR);
      return;
    }

    if(storeItem.donor_id === null && location.pathname !== '/admin/market-place/items/add'){
      setFormError(constants.ADMIN_COPY_ITEM_DONOR)
      return
    }
    // if(storeItem.auction_item_category_id === null && storeItem.custom_category_id === null){
    //   setFormError(constants.ADMIN_COPY_ITEM_CATEGORY)
    //   return 
    // }
    if(checkValidity()) return
    if(itemType === 'virtual') {
      if( ( virtualText === "" && virtualFiles.length === 0)){
        setFormError(constants.ADMIN_COPY_VIRTUAL_ITEM)
        return;
      }      
    }
    if(canChooseCause && selectedCauses?.length === 0){
      setFormError(constants.ADMIN_COPY_ITEM_SELECT_CAUSE);
      return
    }

    //so the item is advatix (not edi and not selfship)
    if((!shipmentType && !ediShipment) && itemType === 'physical' && storeItem?.quantity_in_stock > 0 && !afterConfirm && godAccess) {
      setContinueAlert({isOpen:true, message:constants.ADMIN_COPY_ADVATIX_ITEM_QUANTITY_IN_STOCK_ALERT, type:'advatix_quantity'})
      return
    }
    let data = prepareSubmitData(storeItem, afterConfirm);
    setIsLoading(true)
    try {
      setShowPrompt(false)
      const auctionItemResponse = await auctionServices.createAuctionBuyNowItem(auction_id, data, forCharity);
      await auctionServices.createAuctionItemAsset(prepareAssetData(auctionItemResponse.data.id), auction_id);
      if(itemType === 'virtual' ){
        let virtualFormData= createVirtualAsset(virtualFiles,virtualText)
        await auctionServices.uploadVirItem(virtualFormData,auction_id,auctionItemResponse.data.id);
      }
      setIsLoading(false)
      // setStoreItem(initialData)
      setSelectedCategory([])
      setShowAmountDialog(true)
      clearUnsavedChanges()
      if(navigateAction === 'another') {
        setReset("reload")
        reload()
      } else if(navigateAction === 'events-list') {
        // navigate('/admin/my-events')
        location.pathname === '/admin/items/add' && navigate('/admin/my-events')
        location.pathname === '/admin/market-place/items/add' && navigate('/admin/market-place')
        location.pathname === '/admin/store/items/add' && navigate('/admin/store')
      } else {
        // navigate(`/admin/event-preview/${auction_id}`)
        location.pathname === '/admin/items/add' && navigate(`/admin/event-preview/${auction_id}`)
        location.pathname === '/admin/market-place/items/add' && navigate(`/admin/market-place-preview/${auction_id}`)
        location.pathname === '/admin/store/items/add' && navigate(`/admin/store-preview/${auction_id}`)
      }
    } catch (error) {
      setIsLoading(false)
      handleSubmitError(error)
      setShowPrompt(true)
    }
  }

  const handleCategoryChange = (e) => {
    // if(e.value.type === 'global') {
    //   setStoreItem({
    //     ...storeItem,
    //     auction_item_category_id: e.value.id,
    //     custom_category_id: null
    //   })
    // } else if (e.value.type === 'custom') {
    //   setStoreItem({
    //     ...storeItem,
    //     custom_category_id: e.value.id,
    //     auction_item_category_id: null
    //   })
    // }
    setShowPrompt(true)
    e?.map((item) => {
      if(item.value == 16 || storeItem.category_ids?.includes(16)) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          category_ids: [16],
          custom_category_ids: []
        }))
       setSelectedCategory(e.filter((cat)=> cat.value === 16)) 
       setCategorySelectDisable(true)
    }
    else {
      setCategorySelectDisable(false)
      if(item.type === 'global' && !storeItem.category_ids?.includes(item.value)) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          category_ids: [...prevStoreItem.category_ids, item.value],
        }))
      }
      else if (item.type === 'custom' && !storeItem.custom_category_ids?.includes(item.value)) {
        setStoreItem((prevStoreItem) => ({
          ...prevStoreItem,
          custom_category_ids: [...prevStoreItem.custom_category_ids, item.value],
        }))
      }
      setSelectedCategory(e)
    }
    })
  }

  const handleCategoryRemove = (e) => {
    const globalCategory = e.filter((catItem, index) => (e.indexOf(catItem) === index) && catItem.type === 'global').map((item) => item.value);
    setStoreItem((prevStoreItem) => ({
      ...prevStoreItem,
      category_ids: globalCategory
    }))
    const customCategory = e.filter((catItem,index) => (e.indexOf(catItem) === index) && catItem.type === 'custom').map((item) => item.value);
    setStoreItem((prevStoreItem) => ({
      ...prevStoreItem,
      custom_category_ids: customCategory
    }))
    setSelectedCategory(e)
  }

  const handleLocalDonor = async () => {
    try {
      setIsLoading(true)
      if(localDonor.org_name?.length < 1 || localDonor.about?.length < 1){
        setIsLoading(false)
        // setFormError("Please fill all fields")
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_FIELD)
        return
      }
      if(localDonor.org_name?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ORG_NAME )
        return
      }
      if(localDonor.about?.trim()?.length < 2) {
        setIsLoading(false)
        alert(constants.ADMIN_COPY_ITEM_LOCAL_DONOR_ABOUT)
        return
      }
      const data = await auctionServices.createLocalDonor({org_name: localDonor.org_name, about: localDonor.about,auction_id:auction_id })
      setUpdateDonors(!updateDonors)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setSelectedDonor({
        label: data.org_name,
        id: data.id
      })
      setStoreItem({
        ...storeItem,
        donor_id: data.id
      })
      setIsLoading(false)
      setOpenDonor(false)
    } catch (error) {
      if(error?.response?.data?.data?.length) {
        setFormError(error?.response?.data?.data[0].msg || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
      }
      else {
        setFormError(error.response.data.message)
      }
      setOpenDonor(false)
      setLocalDonor({
        org_name: "",
        about: ""
      })
      setIsLoading(false)
    }
  }

  const handleDonorChange = (e) => {
    setShowPrompt(true)
    setStoreItem({...storeItem, donor_id: e.value})
    setSelectedDonor(e)
    if(e.sps_edi_trading_partner_id === null) {
      setEdiShipment(false)
    }
  }

  const deleteImage = async (url, imageIdx) => {
    setLandscapeImages(landscapeImages.filter((i,idx)=> idx !== imageIdx))
    setImageObjects2(imageObjects2.filter((o, idx) => idx !== imageIdx))
  }

  // const deletePortrait = async (url, imageIdx) => {
  //   setPortraitImage(null)
  //   setImageObjects1("")
  // }

  const handleCauseChange = (e) =>{
    setShowPrompt(true)
    setDefaultStateChange({...defaultStateChange, causeIdChange:true})
    setSelectedDefaultCauseId(e)
    setCauseId(e?.value)
  }

  const onDialogChange = (e) => {
    setShowPrompt(true)
    setDefaultStateChange({...defaultStateChange, dialogeChange: true})
    setShowAmountDialog(e)
 }

 const handleTaxable = () => {
  setShowPrompt(true)
  setDefaultStateChange({...defaultStateChange, taxChange:true})
  setIsTaxable(!isTaxable)
 }

 const handleItemTypeChange = (value) => {
  setShowPrompt(true)
  setDefaultStateChange({...defaultStateChange, itemTypeChange:true})
  setItemType(value)
}

 const handleFlatRate = (mode) => {
  setShowPrompt(true)
  switch (mode) {
    case 'flatrate':
      setDefaultStateChange({...defaultStateChange, isFlatRateChange:true})
      setIsFlatRate(!isFlatRate)
      break;
    case 'hasupc':
      setDefaultStateChange({...defaultStateChange, hasUPCChange:true})
      setHasUPC(!hasUPC)
      break;
    case 'shipmenttype':
      if(shipmentType && !godAccess) {
        // if not god user then we are not allowing them to make it advatix
        setFormError(constants.ADMIN_COPY_NORMAL_USER_SHIPPING_OPTION_ALERT)
      }
      else {
       setDefaultStateChange({...defaultStateChange, shipmentChange:true})
       setShipmentType(!shipmentType)
       setEdiShipment(false);
      }
      break;
    case 'pickuptype':
      setDefaultStateChange({...defaultStateChange, pickUpChange:true})
      setPickupType(!pickupType)
      break;
    case 'ediShipment':
      if(keyName !== 'marketplace' || !auctionData?.donor?.sps_edi_trading_partner_id) {
        if(!selectedDonor) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
        if(!selectedDonor?.sps_edi_trading_partner_id || selectedDonor?.sps_edi_trading_partner_id === null || selectedDonor?.sps_edi_trading_partner_id === undefined) {
          setFormError(constants.ADMIN_COPY_EDI_SHIPMENT_ALERT)
          return
        }
      }
      setDefaultStateChange({...defaultStateChange, ediShipmentChange:true});
      setEdiShipment(!ediShipment);
      setShipmentType(false);
      break;
    default:
      break;
  }
  
}

const handleVirtualTextChange = (e) => {
  setShowPrompt(true)
  setVirtualText(e.target.value)
}

const clearUnsavedChanges = () => {
  localStorage.removeItem(localStoreExtraDataName)
  localStorage.removeItem(localStoreDataName)
  setStoreItem(initialStateCopy)
  setSelectedCategory(null)
  setSelectedDonor(null)
  setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
  if(keyName !== 'marketplace') {
    setSelectedDefaultCauseId(null)
  }
  setSelectedCauses([])
  setShowAmountDialog(true)
  setItemType("physical")
  setVirtualText("")
  setVirtualFiles([])
  setPickupType(false)
  // setShipmentType(false)
  changeShippingType()
  setHasUPC(false)
  setIsFlatRate(false)
  setLandscapeImages([])
  setImageObjects2([])
  dispatch(clearAttribute({key:storeItemImagesReduxName}))
  dispatch(clearAttribute({key:virtualAssetReduxName}))
  setDefaultStateChange({
    causeIdChange: false, 
    taxChange: false, 
    dialogeChange: false,
    storeItemImageChangeState: false,
    itemTypeChange: false,
    pickUpChange:false,
    shipmentChange: false,
    hasUPCChange: false,
    isFlatRateChange: false,
    storeItemVirtualFileChange: false
  })
}

const handleContinueAlert = async() => {
  setContinueAlert({isOpen:false, message:'', type:''})
  switch (continueAlert.type) {
    case 'advatix_quantity':
      await handleSubmit(null, true)
      break;
    default:
      break;
  }
}

const handleAiAnalysisClicked = async() => {
  if(aiAnalysis.isUsed) return;
  if (imageObjects2.length === 0) {
    setFormError(constants.ADMIN_COPY_ITEM_IMG_LANDSCAPE)
    return true
  }
  try {
    setIsLoading(true)
    const formData = new FormData()
    const newBlob = new Blob([imageObjects2[0]], { type: "image/jpeg" })
    formData.append('image', newBlob)
    const response = await auctionServices.generateImageDetails(formData)
    // if(response?.data?.status === 'success') {
    if(response?.data?.description?.length) {
        setAiAnalysis({modalIsOpen:true, aiData:response?.data, isUsed:true})
    }
    // setAiAnalysis({...aiAnalysis, modalIsOpen:true, isUsed:true})
    setIsLoading(false)
  }catch(error) {
    setIsLoading(false)
    setAiAnalysis({modalIsOpen:false, aiData:null, isUsed:false})
    setFormError(error?.response?.data?.message || constants.ADMIN_COPY_SOMETHING_WENT_WRONG)
  }
}

const handleGeneratedData = (data=null) => {
  if(!data) return;
  setShowPrompt(true)
  setStoreItem({...storeItem, description: data?.description})
  setAiAnalysis({...aiAnalysis, modalIsOpen:false, isUsed:true})
}

const showAiGeneratedData = () => {
  setAiAnalysis({...aiAnalysis, modalIsOpen:true})
}

const onStockAvailabityChange = (e) => {
  setShowPrompt(true)
  setStoreItem({...storeItem, show_stock_availability:e})
}

  return (
    <form onSubmit={handleSubmit} className="sitem-wrapper">
      <div className='form-top-actions'>
      {
        !aiAnalysis.isUsed ? 
        <button onClick={handleAiAnalysisClicked} type='button' className='aitem-modal-btn'>AI Analysis</button>
        :
        <button onClick={showAiGeneratedData} type='button' className='aitem-modal-btn'>Show AI Generated Data</button>
      }
      {
      (localStorageData || Object.keys(localStorageExtraStoreItemData)?.length) ?
          <ClearButton onClick={clearUnsavedChanges}/>
          :null
      }
      </div>
      <div className='sitem-container'>
        <div>
          <div className='sitem-section-head'>
            General
            <hr />
          </div>
          <Input label="Store Item Title" value={storeItem.name} name="name" onChange={handleDataChange} required />
            <div>
            {/* <DropDown isSearchable onChange={handleCategoryChange} options={categories} label="Choose Category" required /> */}
            <label className='input-label' htmlFor="">Choose Category</label>
              <Multiselect 
                displayValue="key"
                onRemove={(e)=>handleCategoryRemove(e)}
                onKeyPressFn={function noRefCheck(){}}
                onSelect={(e)=>handleCategoryChange(e)}
                options={categories}
                showArrow
                avoidHighlightFirstOption
                placeholder="Choose Category"
                // disable={categorySelectDisable}
                selectionLimit={categorySelectDisable ? 1 : null}
                selectedValues={selectedCategory}
              />
            </div>
          <div className='aitem-two-inputs' style={{gap: '10px'}}>
            { !location.pathname.startsWith('/admin/market-place')
             ?
            <div style={{flex: 3, display: "flex", alignItems: "flex-end", gap: "10px"}}>
              <DropDown value={selectedDonor} isSearchable onChange={handleDonorChange} options={donorsList} label="Donor" required />
              <button onClick={() => setOpenDonor(true)} type='button' className='aitem-modal-btn'>Add Donor</button>
            </div>
            : null}
          </div>
          {location.pathname.includes('/admin/store') ? null : 
          <div style={{marginTop:"15px"}}>
          <label style={{ fontWeight:"600",fontSize:"0.85rem"}} >{constants.ADIMIN_COPY_CAN_CHOOSE_CAUSE}</label>
          <div style={{ display: "flex", gap: "20px" }}>        
            <FormControlLabel
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="Yes"
              checked={canChooseCause === true}
              onChange={()=>setCanChooseCause(true)}
              labelPlacement="end"
            />
            <FormControlLabel  
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}} />}
              label="No"
              checked={canChooseCause === false}
              onChange={()=>setCanChooseCause(false)}
              labelPlacement="end"
            />
          </div>
         {
          (canChooseCause) ?  <Multiselect 
          displayValue="key"
          onRemove={(e)=>setSelectedCauses(e)}
          onKeyPressFn={function noRefCheck(){}}
          onSelect={(e)=>setSelectedCauses(e)}
          options={causes}
          showArrow
          avoidHighlightFirstOption
          placeholder="Select Causes"
          selectionLimit={4}
          selectedValues={selectedCauses}
        /> :  <DropDown 
                value={selectedDefaultCauseID} 
                isSearchable 
                onChange={handleCauseChange} 
                options={defaultCauses} 
                label="Select Cause" 
                // required={!location.pathname.includes('/market-place')? true : false}
                required={true}
                isDisabled={keyName === 'marketplace'}
              />
         }
          </div>}
          <FormTextarea iButton infoBubbleText='Add a short but clear description about your item' label="Item Short Description" value={storeItem.short_description} name="short_description" onChange={handleDataChange} required rows={1} maxsize={75} />
          <FormTextarea iButton infoBubbleText='Describe your item in greater detail' label="Item Long Description" value={storeItem.description} name="description" onChange={handleDataChange} required maxsize={1000} />
          <div className='aitem-two-inputs'>
            
            <Input label={ auctionData?.market_place == 1 ? "Boundless Price" : (location.pathname === '/admin/store/items/add')?`Boundless Price`:`${upperName} Price`} value={storeItem.price} name="price" onChange={handleDataChange} required type="number" step="any" />
            <Input iButton infoBubbleText='If you want to  display a retail or MSRP price for comparison purposes, enter it here.' label="List / Retail Price" value={storeItem.list_price} name="list_price" onChange={handleDataChange} type="number" step="any" />
            { auctionData.ui === 'flash_sale' || auctionData.discount_percentage !== null ?
            <Input iButton infoBubbleText='It will be applied once Flash Sale Starts .If you leave it blank then flash sale price will be discounted from bulk discount.' label="Flash Sale Price" value={storeItem.discounted_price} name="discounted_price" onChange={handleDataChange} type="number" step="any" />
            : null
            }
          </div>
          <FormControlLabel onChange={handleTaxable} control={<Checkbox size="small" checked={isTaxable} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={<Typography sx={{fontSize:'0.85rem',fontWeight:'600',fontFamily:'AvenirNext, sans-serif'}}>Is this item taxable?</Typography>} />
          {(ediShipment || shipmentType) &&<Input 
            iButton infoBubbleText='If quantity is unlimited, enter 5000' 
            label="Quantity in stock" 
            value={storeItem.quantity_in_stock} 
            name="quantity_in_stock" 
            onChange={handleDataChange} required type="number" 
          />}
        </div>
        <div>
        <div className='sitem-section-head'>
            Add Sizes, Colors And Styles (Optional)
            <hr />
          </div>
          <div style={{margin: "10px 0"}}>
            <button type="button" onClick={() => setShowOptions(true)} className='aitem-modal-btn '>
              {
                Object.keys(storeItem?.options)?.length ?
                'Add/Edit Options'
                :
                ' Add Options'
              }
            </button>
            <button disabled={!localVariants || localVariants.length === 0} style={{marginLeft: "10px"}} type="button" onClick={() => setShowVariants(true)} className='aitem-modal-btn '>Add Variants Information </button> {(localVariants.length > 0 ) ?  <Tooltip title={<Typography fontSize="0.85rem">Add options details like quantities/skus/UPCs.</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize: "25px", marginLeft: "3px"}} /></Tooltip> :  ""}
          </div>
        {!localVariants || localVariants.length === 0 ? null : <div style={{margin: "10px 0"}}>
           <label className='input-label'>Listed below are the options that you have added</label>
          <OptionsModalContent setOptionsUpdated={setOptionsUpdated} options={storeItem.options}  deleteOption={deleteOption} outsideModal={true} />
          </div> }  

          {!localVariants?.length ?
              <Input
                iButton infoBubbleText={constants.ADMIN_COPY_ITEM_EDI_INFO}
                label="Vendor SKU ID" value={storeItem.vendor_sku_id} name="vendor_sku_id" onChange={handleDataChange}
              />
              :null
          }

        <RadioGroupComponent 
          checked={showAmountDialog}
          onChange={onDialogChange}
          label={'Would you like to display a dialog that tells the buyer how much will go to cause?'}
        
        />

        <RadioGroupComponent 
          checked={storeItem.show_stock_availability}
          onChange={onStockAvailabityChange}
          label={constants.ADMIN_COPY_ITEM_STOCK_AVAILABILITY}
        
        />

          <div className='sitem-section-head'>
            Images
            <hr />
          </div>

          {/* <ImageInput loading={portraitImageLoading} required edit onDelete={deletePortrait} handleChange={(e)=>handlePortraitImage(e,false)} images={portraitImage ? portraitImage : []} label={constants.ADMIN_COPY_ITEM_THUMPNAIL_IMG} sidelabel="Max 1, Recomended size 300x300" max={1} /> */}
          <ImageInput loading={landscapeImageLoading} required edit onDelete={deleteImage} multiple handleChange={(e)=>handleLandscapeImage(e,false)} images={landscapeImages} label={constants.ADMIN_COPY_ITEM_DETAILS_IMG} sidelabel="Max 4, recommended size 1024x1024" max={4} reset={reset} setReset={(newState)=>setReset(newState)}/>

          <div className='sitem-section-head' style={{marginTop: "15px"}}>
            Shipping
            <hr />
          </div>
          <div style={{display: "flex", gap: "20px"}}>
            <FormControlLabel
              value="physical"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}  />}
              label="Physical Product"
              checked={itemType === "physical"}
              onChange={() => handleItemTypeChange("physical")}
              labelPlacement="end"
            />
            <FormControlLabel
              value="Virtual"
              control={<Radio size='small' sx={{"&.Mui-checked": {color: redColor,}}}  />}
              label="Virtual Product"
              checked={itemType === "virtual"}
              onChange={() => handleItemTypeChange("virtual")}
              labelPlacement="end"
            />
          </div>
          <div>
        </div>
          {itemType === 'physical' ? <button type="button" onClick={() => setOpenShipping(true)} className='aitem-modal-btn aitem-white-button' style={{width: "100%"}}>Add Shipping/Pickup Details</button> : null}
          {(itemType === 'virtual') && <>
            <FormTextarea label="Enter email body for how to receive or claim item" value={virtualText} name="description"   maxsize={500} onChange={handleVirtualTextChange} iButton infoBubbleText='Please supply email instructions explaining how item winner/purchaser may receive or claim their purchase.' />
          </>}
          {(itemType === 'virtual') && <>
            <label className='input-label' style={{marginBottom: 0, marginTop: "10px"}}>Upload attachments with additional instructions (if required)<Tooltip title={<Typography fontSize="0.85rem">Please upload a .pdf, .jpg or .png to send to the auction winner that includes the digital item or instructions for redemption</Typography>}><InfoOutlinedIcon sx={{color:'#495057c4',fontSize: "16px", marginLeft: "3px"}} /></Tooltip></label>
            <input type='file'  className='aitem-file-btn' style={{marginTop: 0}} multiple={true} onChange={handlefileChange}/>           
          </>
          }
          <div className='aitem-submit-container'>
            <button onClick={() => setNavigateAction("another")} type='submit'>Save & Add Another Item</button>
            {auction_status && auction_status === 'live' ? <button onClick={() => setNavigateAction('events-list')} type="submit">Finished Adding? Save and Manage Events</button> : <button onClick={() => setNavigateAction("finish")} type='submit'>Finished Adding Items? Save and Preview {upperName}</button>}
          </div>
        </div>
      </div>
      <div style={{fontSize: "0.9rem", fontWeight: "600", padding: "0 10px"}}><span style={{color: "red"}}>*</span> - required fields</div>
      <Modal buttonRequired={false}  isOpen={showOptions} onClose={() => setShowOptions(false)} title="Add Options">
          <OptionsModalContent onSubmitOptions={onSubmitOptions} setOptionsUpdated={setOptionsUpdated} options={storeItem.options} addOption={addOption} deleteOption={deleteOption} />
      </Modal>
      <Modal isOpen={openShipping} onClose={() => setOpenShipping(false)} onDone={() => setOpenShipping(false)} title="Shipping Details">
        <div className='input-label' style={{fontSize: "1.2rem", fontWeight: "600", borderBottom: "1px solid #d3d3d3"}}>Check all that applies</div>
        <FormControlLabel onChange={() => handleFlatRate('flatrate')} control={<Checkbox size="small" checked={isFlatRate} sx={{"&.Mui-checked": {color: redColor,}}}  />} label="Flat Rate Shipping"/>
        <br />       
        <FormControlLabel onChange={() => handleFlatRate('ediShipment')} control={<Checkbox size="small" checked={ediShipment} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={constants.ADMIN_COPY_EDI_SHIPMENT_LABEL}/>
        <FormControlLabel onChange={() => handleFlatRate('shipmenttype')} control={<Checkbox size="small" checked={shipmentType} sx={{"&.Mui-checked": {color: redColor,}}}  />} label={ location.pathname.includes('/market-place') ? 'I Will Provide Shipping & Handling (Drop Ship)' : `Shipping & Handling Managed By ${upperName} Creator`}/>
        <br />
       { !location.pathname.includes('/market-place') &&
       <>
        <FormControlLabel onChange={() => handleFlatRate('hasupc')} control={<Checkbox size="small" checked={hasUPC} sx={{"&.Mui-checked": {color: redColor,}}}  />} label="This product has UPC"/>
        {hasUPC && <Input label="Enter UPC" required value={storeItem.upc} onChange={handleDataChange} name="upc" />}
        <br />
       </>}
        {shipmentType && <FormControlLabel onChange={() => handleFlatRate('pickuptype')} control={<Checkbox size="small" checked={pickupType} sx={{"&.Mui-checked": {color: redColor,}}}  />} label="No shipping. Customer must pick up from designated location."/>} 
        {!pickupType&&<Input type="number" name={'weight'} value={storeItem.weight} onChange={handleDataChange} label="Product Weight (lb)" required={!pickupType} iButton infoBubbleText='If your item is one pound or less, enter the number 1.'/>}
      </Modal>
      <Modal onDone={onSubmitVariants} isOpen={showVariants} onClose={handleCloseVariants} title="Add Varaints">
        <VaraintsModalContent localVariants={localVariants} setLocalVariants={setLocalVariants} setShowPrompt={setShowPrompt} />
      </Modal>
      <Modal isOpen={openDonor} onClose={() => setOpenDonor(false)} onDone={handleLocalDonor} title="New Donor">
        <Input value={localDonor.org_name} onChange={(e) => setLocalDonor({...localDonor, org_name: e.target.value})} label="Donor Name or Organization" required />
        <FormTextarea value={localDonor.about} onChange={(e) => setLocalDonor({...localDonor, about: e.target.value})} label="Long Description" required iButton infoBubbleText='Tell us about your item’s donor. Who are they? What do they do? Do you want to share a link to their web site?'/>
      </Modal>

      <Modal isOpen={aiAnalysis.modalIsOpen} onClose={() => setAiAnalysis({...aiAnalysis, modalIsOpen:false})} buttonRequired={false} title={constants.ADMIN_COPY_AI_MODAL_TITLE}>
        <AIGeneratedData data={aiAnalysis.aiData} handleGeneratedData={handleGeneratedData}/>
      </Modal>

      <LoadingModal isOpen={isLoading} />
      <Alert isOpen={formError} message={formError} onClose={() => setFormError(null)} />
      {/* <Alert isOpen={!!whiteborderPortraitAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handlePortraitImage(portraitEvent,true);
                setWhiteborderPortraitAlert(null)
                }} 
                onClose={()=>setWhiteborderPortraitAlert(null)} 
                message={whiteborderPortraitAlert}/> */}
      <Alert isOpen={!!whiteborderLandScapeAlert} type='confirm' okBtnText='Upload Anyway' 
            onConfirm={()=>{
                handleLandscapeImage(landScapeEvents,true);
                setWhiteborderLandScapeAlert(null)
                }} 
                onClose={()=>setWhiteborderLandScapeAlert(null)} 
                message={whiteborderLandScapeAlert}/>
      <Alert 
          isOpen={continueAlert.isOpen} 
          message={continueAlert.message} 
          onClose={() => setContinueAlert({isOpen:false, message:'', type:''})}
          type='confirm'
          onConfirm={handleContinueAlert}
          okBtnText='I Understand, Proceed'
      />
    </form>
  )
}

export default StoreItem